import React from "react";

import * as Styled from './styled';


export const Possibilities = () => (
    <Styled.Container>
        <Styled.Heading>Partnership possibilities</Styled.Heading>
        <Styled.FeaturesWrapper>
          <Styled.Feature>
              <Styled.FeatureImageContainer bg="linear-gradient(0deg, #526EFF 0%, #031262 100%)">
                  <Styled.FeatureImage alt="My Drama app" src="/img/series-licensing-new.webp" />
              </Styled.FeatureImageContainer>
              <Styled.FeatureTextContainer>
                  <Styled.FeatureHeading>
                    My Drama Series Licensing
                  </Styled.FeatureHeading>
                  <Styled.FeatureDescription>
                    We produce top-tier Vertical content and are willing to share it with new audiences. Our speciality is Romantic content for the female audience. My Drama is open to publishing exclusive content on other platforms. If you are interested in licensing our content - feel free to reach out.
                  </Styled.FeatureDescription>
              </Styled.FeatureTextContainer>
          </Styled.Feature>

          <Styled.Feature>
              <Styled.FeatureImageContainer bg="linear-gradient(0deg, #031262 0%, #526EFF 100%)">
                  <Styled.FeatureImage alt="My Drama app" src="/img/co-production-new.webp" />
              </Styled.FeatureImageContainer>
              <Styled.FeatureTextContainer>
                  <Styled.FeatureHeading>
                    Product / Brand placement
                  </Styled.FeatureHeading>
                  <Styled.FeatureDescription>
                    With My Drama you can increase your brand recognition and influence potential customers. Wether it is Virtual/Visual/Vocal, Direct or Indirect product placement option - MyDrama will make sure your brand is getting the most of our collaboration.
                  </Styled.FeatureDescription>
              </Styled.FeatureTextContainer>
          </Styled.Feature>
          
          <Styled.Feature>
              <Styled.FeatureImageContainer bg="linear-gradient(0deg, #526EFF 0%, #031262 100%)">
                  <Styled.FeatureImage alt="My Drama app" src="/img/brand-placement-new.webp" />
              </Styled.FeatureImageContainer>
              <Styled.FeatureTextContainer>
                  <Styled.FeatureHeading>
                    Co-production
                  </Styled.FeatureHeading>
                  <Styled.FeatureDescription>
                    My Drama is constantly creating new series and we plan to launch 100 more unique projects this year. Make sure you hop on the train and invest in a Top-Tier series for you product.
                  </Styled.FeatureDescription>
              </Styled.FeatureTextContainer>
          </Styled.Feature>
        </Styled.FeaturesWrapper>
    </Styled.Container>
)